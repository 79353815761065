import { Route, Routes } from "react-router-dom";
import Disclaimer from "./disclaimer";
import Privacy from "./privacy";
import About from "./about";
import Contact from "./contact";
import Footer from "./footer";

function PageRoute() {
  return (
    <>
      <Routes>
        <Route path="/contact" element={<Contact />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}

export default PageRoute;
