import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";

function DaySection({ dayGameData }) {
  const location = useLocation();
  const isContact = location.pathname.includes("/contact");
  const isDisc = location.pathname.includes("/disclaimer");
  const isPrivacy = location.pathname.includes("/privacy");
  const isAbout = location.pathname.includes("/about");

  const prevDate = moment()
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  const [gameResult, setGameResult] = useState([]);

  const [data, setGameData] = useState([]);
  const currentTime = moment().format("HH:mm");
  const currentDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    setGameData(dayGameData);
  }, [dayGameData]);

  // based on current date and time get data
  const getTodayResult = (gameData) => {
    const itemTime = moment(gameData.open_time, "HH:mm");
    const currentMoment = moment(currentTime, "HH:mm");

    if (gameData?.curr_date?.date === currentDate) {
      return currentMoment.isSameOrAfter(itemTime)
        ? gameData?.curr_date?.result || ""
        : "";
    }
    return "";
  };

  return (
    <div className="" id = 'dayResult'>
      {!(isAbout || isContact || isDisc || isPrivacy) && (
        <div className="col-12 mt-5 daywisereport bg-white">
          <h3 className="text-white text-center">Matka Result</h3>
          <div className="row">
            {data.length > 0 ? (
              data.map((gameData, index) => (
                <div
                  key={index}
                  className={`col-md-${
                    index === 0 ? 12 : 6
                  } col-sm-12 game_column`}
                >
                  <div className="d-flex align-items-center flex-column col-lg-12">
                    <h6 className="mb-0 fw-bold fs-6 text-white day-success w-100 text-center p-2">
                      {gameData?.game_name}
                    </h6>
                    <p className="mb-0 fs-6 fw-bold">
                      ( Time {gameData?.open_time} )
                    </p>
                    <div className="d-flex day-result align-items-end text-center">
                      <div>
                        <p className="mb-0 fs-6 fw-bold text-danger">OLD</p>
                        <span className="btn text-white fw-bold">
                          {gameData?.prev_date?.result || "{ }"}
                        </span>
                      </div>
                      <div>{/* <img src={myImage} alt="Result Image"/> */}</div>
                      <div>
                        <p className="mb-0 fs-6 fw-bold text-danger">NEW</p>
                        <span className="btn text-white fw-bold">
                           {getTodayResult(gameData)|| "{ }"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No results available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DaySection;
