import React from "react";

function Contact() {
  return (
    <div className="col-md-12 p-5 text-start contact">
      <h2 className="fw-bold">Display Your Ad </h2>
      <p>
        If You Are A Satta Game leader and Manager and Want To Display Your ad
        on Our website Then, Kinldy WhatsApp on Given Number
      </p>
      <p>MANAGER : +91 9311251105</p>
    </div>
  );
}

export default Contact;
