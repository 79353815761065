function Disclaimer() {
  return (
    <div className="col-md-12 p-5 text-start disclaimerr">
      <h2 className="fw-bold">Disclaimer:</h2>
      <p>
        If you require any more information or have any questions about our
        site's disclaimer, please feel free to contact us by email at
        info@sattakingrecords.com
      </p>
      <h3>Disclaimers for sattakingrecords.com</h3>
      <p>
        All the information on this website - https://sattakingrecords.com/ - is
        published in good faith and for general information purpose only. satta
        kingfast does not make any warranties about the completeness,
        reliability and accuracy of this information. Any action you take upon
        the information you find on this website (satta king records), is
        strictly at your own risk. satta kingfast will not be liable for any
        losses and/or damages in connection with the use of our website.
      </p>
      <p>
        From our website, you can visit other websites by following hyperlinks
        to such external sites. While we strive to provide only quality links to
        useful and ethical websites, we have no control over the content and
        nature of these sites. These links to other websites do not imply a
        recommendation for all the content found on these sites. Site owners and
        content may change without notice and may occur before we have the
        opportunity to remove a link which may have gone 'bad'.
      </p>
      <p>
        Please be also aware that when you leave our website, other sites may
        have different privacy policies and terms which are beyond our control.
        Please be sure to check the Privacy Policies of these sites as well as
        their "Terms of Service" before engaging in any business or uploading
        any information.
      </p>

      <h3 className="fw-bold">Consent</h3>
      <p>
        By using our website, you hereby consent to our disclaimer and agree to
        its terms.
      </p>

      <h3 className="fw-bold">Update</h3>
      <p>
        Should we update, amend or make any changes to this document, those
        changes will be prominently posted here.
      </p>
    </div>
  );
}

export default Disclaimer;
