
import { useLocation, useNavigation } from 'react-router';
const SattaKingInfo = () => {
  const location = useLocation();
  const isContact = location.pathname.includes('/contact')
  const isDisc = location.pathname.includes('/disclaimer')
  const isPrivacy = location.pathname.includes('/privacy')
  const isAbout = location.pathname.includes('/about')
  return (

    <div className="bg-red">
      {
        (!(isAbout || isContact || isDisc || isPrivacy)) && (
          <>

            <div className="row d-flex  justify-content-center">
              <div className="col-md-12 ">
                <div className="panel p-5 mt-5  panel-default panel-custom">
                  <div className="panel-heading panel-heading-custom">
                    <h5 className="panel-title contact-header2">
                      What Is Satta King?
                    </h5>
                  </div>
                  <div className="panel-body text-dark">
                    <p>
                      <a
                        href="index.php"
                        className="link-white"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Satta King
                      </a>
                      is a famous lottery game with a rich history, dating back to the
                      cotton trades before 1947. This is a game where the player draws
                      numbers from 0 to 99 from a matka (clay pot). Ironically, the
                      actual game is termed Satta Matka by combining two words.
                    </p>
                    <p>
                      Satta + Matka mean gambling or a bet and Matka is the clay pot,
                      often used to store water or things in India. It's the prize
                      money that makes the game to be more lucrative instead of how
                      the game is played. But that's not it, there are many types of
                      lottery games, <span className="font-bold">Satta King 786</span>{" "}
                      is one of them.
                    </p>
                    <p>
                      If you know what the game is, you should also consider that the
                      game has various levels and many types of rules and regulations.
                      In this detailed article, we have compiled a list of all the
                      prominent Sattaking games. These Sattaking games are very
                      popular and celebrated across the globe. Some are popular, while
                      others are not because of their partiality, transparency,
                      commitment, honesty, or weird and awkward rules.
                    </p>
                    <p>
                      To make sure you are on the same page, we've summarized what
                      exactly is Satta king. It is a game played worldwide, and it is
                      illegal in some places. The game was called{" "}
                      <span className="font-bold"></span>Satta Matka and not Satta
                      king back then, but it is a popular name for outdoor gaming.
                      Satta king is one of the oldest gambling games, we have compiled
                      the beginning and other major events that created the Satta King
                      786 games we know today.
                    </p>
                    <p>
                      You can easily grasp or catch up with big changes and other
                      minor changes in the game and how it has changed many people's
                      lives. Also, did I tell you that Satta king games can make you
                      90 times richer than the amount you invest in the business? Yes,
                      but many Satta king 786 websites have spread false information
                      about the game and other vital details that are misleading. Here
                      at Satta king records. We try to give you the exact date and
                      facts and figures to make this article as authentic as possible.
                    </p>
                    <h5 className="font-bold text-center">
                      Authentic History of Satta King!
                    </h5>
                    <p>
                      The history of the Satta Matka games unfolds the same year India
                      started its independent democracy and implemented its
                      constitution. These games began in the 1940s. It was popular
                      back then by the name Satta Matka. Those People used to bet on
                      the closing and opening trade of cotton in the market. The major
                      reasons for the existence of Satta were cotton mills in Mumbai,
                      then Bombay. The laborers of Cotton mills have nothing much to
                      do, apart from the work they are assigned in the cotton mills.
                      The game gave everyone hopes to change their fortune by doing
                      the minimum effort. The Cotton mill laborers were the first
                      people who played the game and saw its massive returns.
                    </p>
                    <p>
                      Likewise, many businessmen also seized the opportunity and
                      started setting up their shops around the mills, as they knew
                      about its audience and the potential of the game. It managed to
                      accumulate cores while people in India were dreamy about their
                      situation and wanted to be rich as quickly as possible,
                      especially after the British raj.
                    </p>
                    <p>
                      Soon the game caught popularity and was churning amounts that
                      nobody expected. More than the players, the game owners or the
                      house took away tons of profits. The Satta Matka was in full
                      swing at that time.
                    </p>
                    <p>
                      The game results or the closing prices of cotton were sent from
                      the Bombay Cotton Exchange to New York Cotton Exchange. Usually,
                      teleprinters were used as the mode of communication in that
                      decade.
                    </p>
                    <ol>
                      <li>
                        The first big change in the Satta king Game came when New York
                        Cotton Exchange banned all types of betting. They saw how
                        laborers invested their whole savings or sold assets to win a
                        fortune and most of them ended up losing all the time.
                      </li>
                      <li>
                        Plus, the game was addictive. There's no way any sane
                        government makes betting legal without exercising any rules
                        and regulations and protocols. However, simply banning a
                        popular game is never the end of the game. We can take the
                        example of the PUBG ban in India.
                      </li>
                      <li>
                        It was at this moment that people started to call the game
                        Satta King instead of Satta Matka. Everyone wanted to be the
                        king and thus the game started getting popularity by the name
                        of spectacular king 786 and not the Satta Matka.
                      </li>
                      <li>
                        One another important piece of information is the number of
                        days the Satta Matka Game used to run. Kalyanji Bhagat's Satta
                        King Games used to run all days of the week, whereas Ratan
                        Khatri's Satta Matka was only played six days a week.
                        <br />
                        It was at this stage that the game{" "}
                        <span className="font-bold">Satta Matka</span> was gaining
                        attention from the public. People were drawn to the game that
                        shows honesty and presents themselves better. These were not
                        the only players in the Sattaking worlds, but these two are
                        often remembered for their honesty, and transparency in the{" "}
                        <span className="font-bold">Satta Matka game</span>.
                      </li>
                      <li>
                        As mentioned earlier, Bombay textile mill workers were the
                        first people addicted to the game. They often invest their
                        hard-earned money and hence, attracted bookies who set up
                        their shops around those mills areas. This way, Bombay became
                        the central hub of{" "}
                        <span className="font-bold">Satta Matka</span>.
                        <br />
                        There were plenty ongoing games, and this helped bookies and
                        the police to build up and shut down their unlawful
                        operations. There are many movies and web series based on the
                        60s of Bombay and the booming business of lottery games like
                        Satta Matka.
                      </li>
                      <li>
                        Apart from the 60s, the Satta king showed a significant boom
                        between the late 80s and early 90s as well. The time when
                        gangsters and the Mafia were ruling the underworld of Mumbai.
                        Their connections with Bollywood too and every businessman was
                        asked for a certain token amount.
                        <br />
                        The shocking part is that these games were having a turnover
                        of 500 Crore rupee per month. Considering the Satta King and
                        the period of the 90s, it generated a huge sum of profits.
                      </li>
                      <li>
                        Finally, things started to settle down for good when the
                        Mumbai police came into action. They raided the various game
                        shops and made arrests. Due to local police intervention, the
                        game suffered a lot too. One big impact was the death of
                        Suresh Bhagat, son of Kalyanji Bhagat. Suresh Bhagat was
                        killed in 2008 and the Satta king game was on the verge of
                        shutting down.
                        <br />
                        However, it would further lead to the expansion of the game.
                        The bookies were forced to take the game outside or shut it
                        down completely. Mumbai Police was consistently raiding
                        betting shops, but the lucrative and appealing game found its
                        audience in other Indian states. Like Gujarat, Rajasthan,
                        Uttar Pradesh, and Delhi as the bookies moved to different
                        towns of the country.
                      </li>
                      <li>
                        Finally, here we are today, according to a report in 2021.
                        There are no federal laws that prohibit online
                      </li>
                    </ol>
                    <p>
                      While the game continues to make news headlines now and then,{" "}
                      <a
                        href="index.php"
                        className="link-white"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Sattaking
                      </a>{" "}
                      is one of the most popular games played today. There are many
                      types of king Satta games such as. Gali Satta game, Faridabad
                      Satta king, Delhi Satta king, Bazaar Satta king, Dishawar Satta
                      king, Kuber Satta king, JD Durga Sattya king, New Faridabad
                      Satta king, and newly popular Bhagya Rekha Satta king.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex mt-5 text-center justify-content-center">
              <div className="col-md-12">
                <div className="panel panel-default panel-custom">
                  <div className="panel-heading panel-heading-custom">
                    <h5 className="panel-title contact-header2">Disclaimer</h5>
                  </div>
                  <div className="panel-body text-dark">
                    <p className="text-black font-size-18">
                      The website is an informational website that aware you of the
                      constant run of the Satta King game. The website owner has
                      nothing to do with the game operations. We are not in contact
                      with any website operators. If you make monetary transactions
                      with any of these numbers available on the website then you are
                      responsible and accountable for your decision. The website owner
                      is not responsible for any fraud.
                    </p>
                    <p className="text-black font-size-18">
                      We strongly advise you to read our privacy policy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center disclaimer-message">
              <p className="satta-4-in">** DISCLAIMER **</p>
              <p className="satta-42-in">
                www.sattakingrecords.com is a Non Commercial Website. We Show Only
                News and Provide Entertainment. Viewing This WebSite Is On Your Own
                Risk. All The information Shown On Website Is Sponsored And We Warn
                You That Satta in Your Country May be Banned or Illegal. We Are Not
                Responsible For Any Issues or Scam. We Respect All Country Rules/Laws.
                If You Not Agree/Satisfied With Our Site Disclaimer, Please Quit Our
                Site Right Now and Never Visit this Site Again. Thank you.
              </p>
              <p className="satta-43-in">!! Sattaleak Team !!</p>
            </div>
          </>
        )
      }



    </div>
  );
};

export default SattaKingInfo;
