import React from "react";
import dmcaImage from "../images/VT-Dmca.webp";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      {/* Footer Buttons Section */}
      <div className="text-center footer pt-3">
        <a
          href="https://api.sattakingvip.co.in/Protection/Status.aspx?ID=a12e3daa-55e6-4875-9669-8f355a572f43"
          rel="nofollow"
          title="DMCA.com Protection Status"
          className="dmca-badge"
        >
          {" "}
          <img
            src={dmcaImage}
            className="img-dmca"
            alt="DMCA.com Protection Status"
            width="200"
            height="35"
          />
        </a>
      </div>

      <div className="col-md-8 col-sm-12 text-center footerBtnGroup pt-2">
        <button
          className="btn bg-warning footer-btn col"
          onClick={() => handleNavigation("/contact")}
        >
          Contact Us
        </button>
        <button
          className="btn bg-warning footer-btn col"
          onClick={() => handleNavigation("/disclaimer")}
        >
          {" "}
          Disclaimer
        </button>
        <button
          className="btn bg-warning footer-btn col"
          onClick={() => handleNavigation("/privacy")}
        >
          {" "}
          Privacy Policy
        </button>
        <button
          className="btn bg-warning footer-btn col"
          onClick={() => handleNavigation("/about")}
        >
          {" "}
          About Us
        </button>
      </div>
    </>
  );
};

export default Footer;
