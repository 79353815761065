import moment from "moment";
import { Col, Table } from 'antd';
import React, { useState, useEffect } from 'react';


function MonthlySatta({ gamedata, dropValue }) {
    const [data, setData] = useState([]);
    const [gameResult, setGameResult] = useState([]);
    const [monthSndYear, setMonth] = useState([]);
    let [columns, setcolumn] = useState([]);
    var date = new Date();
    var currentMonthDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    var getCurrentYear = moment().tz('Asia/Kolkata').year();
    let currentMonth = date.getMonth();

    useEffect(() => {
        if (gamedata) {
            setData(gamedata)
        }
    })
    var selectMonthDrop;
    useEffect(() => {
        if (dropValue) {
            setGameResult(dropValue)
            if (gameResult == '') {
                selectMonthDrop = moment().tz('Asia/Kolkata').format("MMMM");
            } else {
                selectMonthDrop = moment(`${dropValue?.year}-${dropValue?.month}-01`).format('MMMM');
            }
        } else {
        }
    })
    useEffect(() => {
        if (data.length > 0) {

            let array = Object.keys(data[0])
            for (let i = 0; i < array.length; i++) {
                array[i] = { title: array[i] == 'day' ? selectMonthDrop : array[i], dataIndex: array[i], key: array[i] }

            }
            setcolumn(array)
        }
    }, [data])
    useEffect(() => {
        // monthYear = new Date()
        // monthYear = "" + moment(monthYear).format('MMM-yyyy')
        // setMonth(monthYear)
        fetch("https://api.sattakingvip.co.in/getmonthdata", {
          method: "POST", // or 'PUT' depending on your requirements
          headers: {
            "Content-Type": "application/json", // specify the content type
          },
          body: JSON.stringify({
            month: currentMonth + 1,
            year: getCurrentYear,
            gameName: "",
            result: "",
            days: currentMonthDays,
          }),
        })
          .then((response) => response.json())
          .then((json) => {
            setData(json);
          })
          .catch((error) => console.error(error));
    }, []);



    return (
        <div className="monthYrTbl pt-3" id='monthTable'>
            {/* {
                (!(dropValue == null))
                && (
                    <h3 className="text-center bg-dark text-white p-2" style={{ display: 'block' }}>
                        {dropValue?.selectedOption?.value} MONTHLY RECORD CHART {dropValue?.selectedMonth?.label || dropValue.selectMonthnew} - {dropValue?.selecYear?.value || getCurrentYear}
                    </h3>

                )} */}
            {
                (!(dropValue == null))
                && (
                    <h3 className="text-center bg-dark text-white p-2" style={{ display: 'block' }}>
                        {dropValue?.gameName} MONTHLY RECORD CHART {selectMonthDrop} - {dropValue?.year || getCurrentYear}
                    </h3>

                )}
            <div className="table-responsive">
                <div className='table_div' id="scrollbar1">
                    <Table dataSource={data} columns={columns} pagination={false} />
                </div>

            </div>
        </div>

    )
}

export default MonthlySatta