function About() {
  return (
    <div className="col-md-12 p-5 text-start about contact">
      <h2 className="fw-bold">About Us Satta King Records</h2>
      <p>
        Sattakingrecords.com is a portal that will provide you instant and
        fastes result of Gali, Deshawar, faridabad, Ghaziabad and Satta other
        games. We also have yearly and monthly wise recort chart of Gali,
        Deshawar, faridabad, Ghaziybad game. You can check all that record and
        can make a prediction of upcoming number.
      </p>
      <p>
        On this website you will find contact number of many predictor who sell
        their predicted game. We don't give guarantee about any predictor posted
        their contact number on this website.
      </p>
      <p>
        All The Information Shown On Website Is Sponsored And We Warn You That
        Matka Gambling/Satta May Be Banned Or Illegal In Your Country. We Don't
        Run Any Satta Matka Gambling And Don't Have Any Connection With Satta
        Makta/ Satta King Company In Any Way. If Some One Ask You To Pay Money
        Please make money as per your understanding. We Are Not Responsible For
        Any Issues Or Scam. We Respect All Country Rules/Laws. If You Not Agree
        With Our Site Disclaimer. Please Quit Our Site Right Now. Thanks
      </p>
    </div>
  );
}

export default About;
